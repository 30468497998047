// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~antd/dist/antd.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
    @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
    @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
@import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@font-face {
    font-family: "Kalimati-Regular";
    src: local("Kalimati-Regular"),
        url(./assets/css/fonts/Kalimati-Regular.otf) format("opentype");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
.example-highlight > pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;
    code {
        overflow-y: auto;
        display: block;

        span {
            background: none transparent !important;
        }
    }
}

.json > pre {
    background: none #fff !important;
}

.example-code > .json {
    background: none #fff !important;
}

.symbol.symbol-45 > svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

// Aside Minimize Toggle Icon
.aside-toggle {
    position: absolute;
    right: 0;
    bottom: 40px;
    height: 36px;
    width: 36px;
    margin-right: -(36px/2);
    z-index: get($aside-config, base, zindex) + 1;

    i {
        transition: $transition;
    }

    // Aside Minimize Toggle Icon Active State
    .aside-minimize &,
    &.aside-toggle-active {
        i {
            transition: $transition;
            transform: rotateZ(180deg) #{"/*rtl:ignore*/"};

            [direction="rtl"] & {
                transform: rotateZ(-180deg) #{"/*rtl:ignore*/"};
            }
        }
    }
}
.category-card-title {
    height: 6.5rem;
    h1 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.7;
    }
}
.category-img {
    height: 180px;
    width: 180px;
    padding: 40px;
    border-radius: 50%;
    background-color: #e1eeff;
}
.survey-form {
    label {
        font-size: 1.325rem;
        font-weight: 500;
    }
}
.form-control {
    border: none;
    background-color: #f3f6f9;
    &:active,
    &.active,
    &:focus,
    &.focus {
        background-color: #f3f6f9;
        border-color: $input-solid-bg-focus;
        color: $input-solid-color;
        transition: $transition-input;
    }
}
.file-dropzone {
    border: 2px dashed rgb(202, 202, 202);
    border-radius: 5px;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        border: 2px dashed rgba($primary, 0.7);
        * {
            color: $primary;
        }
    }
}
.brand-logo {
    img {
        width: 50px;
    }
}
.invalid-feedback {
    display: block;
}
.btn.btn-secondary.dropdown-toggle.btn-block:after {
    float: right;
    top: 5px;
}
.badge.search-result {
    cursor: pointer;
}
.nav.nav-pills .show > .nav-link,
.nav.nav-pills .nav-link.active {
    color: $primary;
    background-color: #e1f0ff;
}
.rc-table-container .rc-table-content table {
    width: 100%;
}
.table-actions {
    i {
        cursor: pointer;
    }
    .flaticon-edit:hover {
        color: $primary;
    }
    .flaticon-eye:hover {
        color: $info;
    }
    .flaticon-delete:hover {
        color: $danger;
    }
}
.table td,
.table th {
    text-align: center;
    vertical-align: middle;
}
.dataTables_paginate .paginate_button {
    margin: 0 5px 0 5px;
    .page-link {
        padding: 0.5rem 0.85rem;
        border-radius: 5px;
    }
}
.image-to-upload {
    border: 1px dashed rgb(160, 160, 160);
    border-radius: 5px;
}
.wizard-step {
    cursor: pointer;
}
.form-group.table-filter {
    margin-bottom: 0;
}
.table thead th.rc-table-cell {
    vertical-align: middle !important;
}
.house-image {
    max-width: 290px;
    max-height: 163px;
    object-fit: contain;
    width: 100%;
    border-radius: 8px;
}
.house-detail h6 {
    color: #464e5f;
    span {
        color: black;
    }
}
.header-fixed {
    z-index: 9999 !important;
}
.subheader {
    z-index: 9998 !important;
}
.detail-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.current-step {
    * {
        fill: $primary;
    }
}
.inactive-step {
    * {
        fill: #b5b5c3;
    }
}
.offcanvas-overlay {
    z-index: 10000;
}
.offcanvas {
    z-index: 10001;
}
@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 20mm;
}
.rc-slider-mark {
    padding-top: 7px;
}
.rc-slider-step {
    span {
        background-color: rgb(44, 71, 112);
        border-color: rgb(44, 71, 112);
    }
}
.rc-slider-handle {
    background-color: #5bc0de;
    border-color: #5bc0de;
}
.rc-slider-tooltip-inner {
    position: relative;
    top: -23px;
}
.rc-slider-tooltip-arrow {
    display: none;
}
.no-data-container {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100;
    img {
        width: 60%;
    }
}
@media (max-width: 576px) {
    .no-data-container img {
        width: 90%;
    }
}
.tab-content {
    margin-top: 30px;
}
.modal {
    z-index: 10000;
}
.modal-backdrop {
    z-index: 9999;
}
.data-detail-modal {
    max-height: 80vh;
}
.loader-container {
    height: 300px;
}
.Toastify {
    z-index: 1000000;
}
.all-house-detail {
    .card {
        height: 100%;
    }
}
.table-container {
    overflow-x: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    padding: 10px;
    margin: 10px;
}
